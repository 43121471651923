import './pagecontent.scss';

import React, {
  ReactElement,
  ReactNode,
} from 'react';

interface PageContentProps {
    children: ReactNode;
    fullWidth?: boolean;
    alignItems?: 'left' | 'center';
}

const PageContent = (props: PageContentProps): ReactElement => {
    const { children, fullWidth, alignItems } = props;
    return (
        <div className={
            `page-content ${fullWidth ? 'full-width' : ''} ${alignItems === 'left' ? 'align-left' : ''}`
        }>
            {children}
        </div>
    );
};

export default PageContent;

class UpdateProfileResponse {
    static type: string = 'UpdateProfileResponse';
    
    profileUpdateSuccess: boolean;
      
    constructor(response: UpdateProfileResponse) {
        this.profileUpdateSuccess = response.profileUpdateSuccess;
    }
}

export default UpdateProfileResponse;

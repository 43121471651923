import './displaynameedit.scss';

import {
  ChangeEvent,
  ReactElement,
} from 'react';

import UserProfileData from '../../../classes/UserProfileData';
import HelperMethods from '../../../helpers/HelperMethods';
import { EditProfileModalBodyProps } from './types';

const DisplayNameEdit = (props: EditProfileModalBodyProps): ReactElement => {
  const { updatedUserProfileData, setUpdatedUserProfileData, setHasMadeEdit } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setHasMadeEdit(true);
    const newDisplayName = event.target.value;
    setUpdatedUserProfileData(prevState => {
      return HelperMethods.cloneWithUpdates(UserProfileData, prevState, {displayName: newDisplayName});
    });
  }
  
  return (
    <input 
      className='display-name-text-input'
      type='name'
      placeholder='Display Name'
      spellCheck={false}
      value={updatedUserProfileData.displayName}
      onChange={onChange}
    />
  );
};

export default DisplayNameEdit;

import { configureStore } from '@reduxjs/toolkit';

import userProfileDataReducer from './userProfileDataSlice';

export const store = configureStore({
  reducer: {
    userProfileDataReducer: userProfileDataReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

import ProfileDisplayData from './ProfileDisplayData';

class GetProfileDisplayDataResponse {
  static type: string = 'GetProfileDisplayDataResponse';
  
  profileDisplayData: ProfileDisplayData;
  
  constructor(data: GetProfileDisplayDataResponse) {
    this.profileDisplayData = new ProfileDisplayData(data.profileDisplayData);
  }
}

export default GetProfileDisplayDataResponse;

import './socialmedialinksedit.scss';

import React, {
  ChangeEvent,
  ReactElement,
} from 'react';

import {
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaYoutube,
} from 'react-icons/fa6';
import { TbWorldWww } from 'react-icons/tb';

import { EditProfileModalBodyProps } from './types';

type SocialMediaPlatform = 'website'
    | 'instagram'
    | 'facebook'
    | 'youtube'
    | 'spotify';
  
const SocialMediaLinksEdit = (props: EditProfileModalBodyProps): ReactElement => {
    const { updatedUserProfileData, setUpdatedUserProfileData, setHasMadeEdit } = props;

    const onChange = (event: ChangeEvent<HTMLInputElement>, socialMediaPlatform: SocialMediaPlatform) => {
        setHasMadeEdit(true);
        const newSocialMediaLink = event.target.value;
        console.log('newLink:', newSocialMediaLink);
        const updatedData = {
            ...updatedUserProfileData,
            socialMediaLinks: {
                ...updatedUserProfileData.socialMediaLinks,
                [socialMediaPlatform]: newSocialMediaLink
            }
        };
        setUpdatedUserProfileData(updatedData);
    }
    
    return (
        <>
            <div className='social-media-input-item'>
                <div className='social-media-input-field'>
                    <TbWorldWww className='social-media-icon'/>
                    <input
                        className='social-media-input website'
                        type='url'
                        placeholder='Website'
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.website || ''}
                        onChange={event => onChange(event, 'website')}
                    />
                </div>
            </div>
            <div className='social-media-input-item'>
                <div className='social-media-input-field'>
                    <FaInstagram className='social-media-icon'/>
                    <input
                        className='social-media-input instagram'
                        type='url'
                        placeholder='Instagram'
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.instagram || ''}
                        onChange={event => onChange(event, 'instagram')}
                    />
                </div>
            </div>
            <div className='social-media-input-item'>
                <div className='social-media-input-field'>
                    <FaFacebook className='social-media-icon'/>
                    <input
                        className='social-media-input facebook'
                        type='url'
                        placeholder='Facebook'
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.facebook || ''}
                        onChange={event => onChange(event, 'facebook')}
                    />
                </div>
            </div>
            <div className='social-media-input-item'>
                <div className='social-media-input-field'>
                    <FaYoutube className='social-media-icon'/>
                    <input
                        className='social-media-input youtube'
                        type='url'
                        placeholder='YouTube'
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.youtube || ''}
                        onChange={event => onChange(event, 'youtube')}
                    />
                </div>
            </div>
            <div className='social-media-input-item'>
                <div className='social-media-input-field'>
                    <FaSpotify className='social-media-icon'/>
                    <input
                        className='social-media-input spotify'
                        type='url'
                        placeholder='Spotify'
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.spotify || ''}
                        onChange={event => onChange(event, 'spotify')}
                    />
                </div>
            </div>
        </>
    );
};
  
export default SocialMediaLinksEdit;

import HelperMethods from '../helpers/HelperMethods';
import {
  Address,
  CreateAccountPageFormData,
} from '../pages/createaccount/types/types';

class CreateAccountData {
  static type: string = 'CreateAccountData';
  
  legalName: string;
  displayName: string;
  username: string;
  businessType: string;
  industryType: string;
  base64ProfileImage: string;
  currency: string;
  country: string;
  address: Address;
  url: string;
  dob: DoB | null;
  phoneNumber: string;
  
  constructor(data: CreateAccountPageFormData) {
    this.legalName = data.legalName;
    this.displayName = data.displayName;
    this.username = data.username.toLowerCase();
    this.businessType = data.businessType;
    this.industryType = data.industryType;
    this.base64ProfileImage = require('../assets/images/default-profile-image.jpg');
    this.currency = data.currency;
    this.country = data.country;
    this.address = data.address;
    this.url = HelperMethods.getValidUrl(data.url);
    //this.dob = data.dob ? this.getDoBFromDayjs(data.dob) : null;
    this.dob = data.dob ? this.getDoB(data.dob) : null;
    this.phoneNumber = HelperMethods.getValidPhoneNumber(data.phoneNumber);
  }

  private getDoB(value: string): DoB {
    const dobArray: string[] = value.split('-');

    return new DoB(parseInt(dobArray[0]), parseInt(dobArray[1]), parseInt(dobArray[2]));
  }

  // private getDoBFromDayjs(value: Dayjs): DoB {
  //   return new DoB(value.year(), value.month()+1, value.date());
  // }
}

class DoB {
  day: number;
  month: number;
  year: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}

export default CreateAccountData;

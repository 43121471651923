class GetPaymentIntentRequest {
  static type: string = 'GetPaymentIntentRequest';
  
  displayName: string;
  transactionAmount: number;
  currency: string;
  username: string;
  stripeConnectedAccountId: string;
    
  constructor(data: GetPaymentIntentRequest) {
    this.displayName = data.displayName;
    this.transactionAmount = data.transactionAmount;
    this.currency = data.currency;
    this.username = data.username;
    this.stripeConnectedAccountId = data.stripeConnectedAccountId;
  }
}

export default GetPaymentIntentRequest;

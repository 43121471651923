import './paymentconfirmationpageprofile.scss';

import {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import {
  FaCircleCheck,
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaYoutube,
} from 'react-icons/fa6';
import { TbWorldWww } from 'react-icons/tb';
import { TfiPencil } from 'react-icons/tfi';

import ProfileDisplayData from '../../classes/ProfileDisplayData';
import UserProfileData from '../../classes/UserProfileData';
import EditProfileModal, {
  ProfileAttribute,
} from '../vieweditprofile/editprofilemodal/EditProfileModal';

interface PaymentConfirmationPageProfileProps {
  profileDisplayData: ProfileDisplayData;
  userProfileData?: UserProfileData;
  isPreview: boolean;
}

const PaymentConfirmationPageProfile = (props: PaymentConfirmationPageProfileProps) => {
  const { profileDisplayData, userProfileData, isPreview } = props;

  const [profileAttribute, setProfileAttribute] = useState<ProfileAttribute>('thankyouMessage');
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const toggleEditModal = (isModalVisible: boolean, setIsModalVisibleState: Dispatch<SetStateAction<boolean>>, profileAttribute: ProfileAttribute): void => {
    setProfileAttribute(profileAttribute);
    setIsModalVisibleState(!isModalVisible);
  }

  return (
    <div className='thankyou-page-centre-content'>
      <FaCircleCheck className='thankyou-page-circle-check'/>
      <h2 className='payment-successful-text'>Payment Successful</h2>
      { profileDisplayData?.thankyouMessage &&
        <>
          <div className='a-message-from'>A message{profileDisplayData.displayName ? ` from ${profileDisplayData.displayName}:` : ':'}</div>
          <div className='message-section'>
            {profileDisplayData.base64ProfileImage && 
              <img className='thankyou-page-profile-image' src={profileDisplayData.base64ProfileImage} alt='logo'/>
            }
            <div className='message-box'>
              { isPreview &&
                <div className='edit-thankyou-message-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'thankyouMessage')}>
                  <TfiPencil className='edit-profile-pencil-icon'/> 
                </div>
              }
              <div className='message-text'>{profileDisplayData.thankyouMessage}</div>
            </div>
          </div>
        </>
      }
      { isPreview && (!profileDisplayData?.thankyouMessage || profileDisplayData.thankyouMessage === '') &&
        <div className='add-profile-content-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'thankyouMessage')}>
          Add Thank you message
        </div>
      }
      { profileDisplayData?.socialMediaLinks && Object.keys(profileDisplayData.socialMediaLinks).length > 0 &&
        <div className='social-media-section'>
          <div className='find-on-text'>Find {profileDisplayData.displayName ? `${profileDisplayData.displayName}` : ''} on:</div>
          <div className='social-media-icon-section'>
              { isPreview &&
                <div className='edit-social-media-links-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'socialMediaLinks')}>
                  <TfiPencil className='edit-profile-pencil-icon'/> 
                </div>
              }
            { Object.entries(profileDisplayData.socialMediaLinks).map(([socialMedia, link]) => (
              <div key={socialMedia}>
                <a href={link} className={`thankyou-page-social-media-icon ${isPreview ? 'disabled' : ''}`}>
                  {socialMedia === 'website' && <TbWorldWww/>}
                  {socialMedia === 'instagram' && <FaInstagram/>}
                  {socialMedia === 'facebook' && <FaFacebook/>}
                  {socialMedia === 'youtube' && <FaYoutube/>}
                  {socialMedia === 'spotify' && <FaSpotify/>}
                </a>
              </div>
            ))}
          </div>
        </div>
      }
      { isPreview && (!profileDisplayData?.socialMediaLinks || Object.keys(profileDisplayData.socialMediaLinks).length === 0) &&
        <div className='add-profile-content-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'socialMediaLinks')}>
          Add Social Media Links
        </div>
      }
      { isPreview && userProfileData &&
        <EditProfileModal
          userProfileData={userProfileData}
          profileAttribute={profileAttribute}
          onClose={() => toggleEditModal(editModalVisible, setEditModalVisible, profileAttribute)}
          isVisible={editModalVisible}
        />
      }
    </div>
  );
}

export default PaymentConfirmationPageProfile;

class CreateAccountResponse {
    static type: string = 'CreateAccountResponse';
    
    accountCreationSuccess: boolean;
      
    constructor(response: CreateAccountResponse) {
        this.accountCreationSuccess = response.accountCreationSuccess;
    }
}

export default CreateAccountResponse;

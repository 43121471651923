import { CreateAccountPageFormData } from '../pages/createaccount/types/types';
import CreateAccountData from './CreateAccountData';

class CreateAccountRequest {
    static type: string = 'CreateAccountRequest';
    
    createAccountData: CreateAccountData;
    recaptchaToken: string|null;
      
    constructor(createAccountFormData: CreateAccountPageFormData, recaptchaToken: string|null) {
        this.createAccountData = new CreateAccountData(createAccountFormData);
        this.recaptchaToken = recaptchaToken;
    }
}

export default CreateAccountRequest;

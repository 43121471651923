import {
  ReactElement,
  useState,
} from 'react';

import NotSignedInNavbar from '../../components/navbar/NotSignedInNavbar';
import EnterEmailAddressPage from './EnterEmailAddressPage';
import EnterOTPPage from './EnterOTPPage';

const AuthenticatePage = (): ReactElement => {
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [showEnterOTPPage, setShowEnterOTPPage] = useState<boolean>(false);
    
    return (
        <>
            <NotSignedInNavbar/>
            { showEnterOTPPage ?
                <EnterOTPPage emailAddress={emailAddress} setShowEnterOTPPage={setShowEnterOTPPage}/>
                :
                <EnterEmailAddressPage setShowEnterOTPPage={setShowEnterOTPPage} setEmailAddress={setEmailAddress}/>
            }
        </>
    );
}

export default AuthenticatePage;

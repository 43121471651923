class CreateStripeAccountSessionResponse {
    static type: string = 'CreateStripeAccountSessionResponse';
    
    clientSecret: string;
      
    constructor(response: CreateStripeAccountSessionResponse) {
        this.clientSecret = response.clientSecret;
    }
}

export default CreateStripeAccountSessionResponse;

import './emailqrcodemodal.scss';

import {
  ChangeEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import ApiResponse from '../../classes/ApiResponse';
import SendQRCodeByEmailRequest from '../../classes/SendQRCodeByEmailRequest';
import SendQRCodeByEmailResponse from '../../classes/SendQRCodeByEmailResponse';
import OmniaModal from '../../components/OmniaModal';
import Constants from '../../configuration/constants';

interface EmailQRCodeModalProps {
    createQRCodeHTMLElement: () => Promise<HTMLElement | null>;
    buildPngFromHTMLElement: (element: HTMLElement) => Promise<string>;
    onClose: () => void;
    isVisible: boolean;
}

const EmailQRCodeModal = (props: EmailQRCodeModalProps): ReactElement => {
    const { createQRCodeHTMLElement, buildPngFromHTMLElement, onClose, isVisible } = props;
    
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);
    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');

    const navigate = useNavigate();

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setEmailAddress(event.target.value);
    }

    useEffect(() => {
        if (!isVisible) {
            setEmailAddress('');
            setSendingEmail(false);
        }
    }, [isVisible]);

    const emailQRCode = async (): Promise<void> => {
        setSendingEmail(true);
        
        const element: HTMLElement | null = await createQRCodeHTMLElement();
        if (element) {
            // Ensure element is visible and fully rendered
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second

            try {
                const dataUrl = await buildPngFromHTMLElement(element);

                //const base64EncodedQRCode: string = dataUrl.split(',')[1]; // Extract base64 part
                await ApiResponse.getApiResponse(
                    Constants.sendQRCodeByEmailEndpoint,
                    new SendQRCodeByEmailRequest(dataUrl, emailAddress),
                    SendQRCodeByEmailResponse,
                    null,
                    null,
                    true
                ).then(response => {
                    if (!response || (response instanceof AxiosError && response.request.status === 401)) {
                        navigate('/authenticate');
                    } else if (response instanceof AxiosError) {
                        setCustomerErrorMessage(response.response?.data);
                        setSendingEmail(false);
                    } else {
                        setSendingEmail(false);
                        onClose();
                    }
                });
                setCustomerErrorMessage('');
            } catch (error) {
                console.error('Error sending QR code email:', error);
            }
        }
        setSendingEmail(false);
        onClose();
    };

    return (
        <OmniaModal
            modalTitle='Email QR Code'
            modalDescription='Enter the email address you wish to receive your unique QR code. Please note it can occasionally take over a minute for the email to arrive.'
            modalBody={
                <input 
                    className='email-qr-code-input'
                    type='email'
                    id='emailAddress'
                    placeholder='example@email.com'
                    spellCheck={false}
                    value={emailAddress}
                    onChange={onChange}
                />
            }  
            actionFunction={emailQRCode}
            actionVerb='Send'
            actionButtonDisabled={emailAddress === ''}
            actionInProgress={sendingEmail}
            onClose={onClose}
            customerErrorMessage={customerErrorMessage}
            isVisible={isVisible}
        />
    );
}

export default EmailQRCodeModal;

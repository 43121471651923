import {
  BusinessType,
  Country,
  Currency,
  IndustryType,
} from '../types/types';

interface CreateAccountPageConfig {
    industryTypes: IndustryType[];
    currencies: Currency[];
    businessTypes: BusinessType[];
    countries: Country[]
}

const createAccountPageConfig: CreateAccountPageConfig = {
    industryTypes: [
        IndustryType.BANDS_ORCHESTRAS,
        IndustryType.BAKERIES,
        IndustryType.BARBER_AND_BEAUTY_SHOPS,
        IndustryType.CATERERS,
        IndustryType.CAR_WASHES,
        IndustryType.COUNTRY_CLUBS,
        IndustryType.COMMUTER_TRANSPORT_AND_FERRIES,
        IndustryType.CLEANING_AND_MAINTENANCE,
        IndustryType.DANCE_HALL_STUDIOS_SCHOOLS,
        IndustryType.DRINKING_PLACES,
        IndustryType.EDUCATIONAL_SERVICES,
        IndustryType.EATING_PLACES_RESTAURANTS,
        IndustryType.FAST_FOOD_RESTAURANTS,
        IndustryType.GENERAL_SERVICES,
        IndustryType.GOLF_COURSES_PUBLIC,
        IndustryType.HEALTH_AND_BEAUTY_SPAS,
        IndustryType.HOTELS_MOTELS_AND_RESORTS,
        IndustryType.MISCELLANEOUS_BUSINESS_SERVICES,
        IndustryType.MISCELLANEOUS_FOOD_STORES,
        IndustryType.MISCELLANEOUS_GENERAL_SERVICES,
        IndustryType.NON_DURABLE_GOODS,
        IndustryType.RELIGIOUS_GOODS_STORES,
        IndustryType.SPORTS_CLUBS_FIELDS,
        IndustryType.TAXI_CAB_LIMOUSINES,
        IndustryType.TRAVEL_AGENCIES_TOUR_OPERATORS,
        IndustryType.TRANSPORTATION_SERVICES
    ],
    currencies: [
        Currency.GBP
    ],
    businessTypes: [
        BusinessType.INDIVIDUAL,
        BusinessType.COMPANY,
        BusinessType.NON_PROFIT
    ],
    countries: [
        Country.UNITED_KINGDOM
    ]
}

export default createAccountPageConfig;

import './invalidinputerrormessage.scss';

import { ReactElement } from 'react';

interface InvalidInputErrorMessageProps {
    errorMessage: string;
}

const InvalidInputErrorMessage = (props: InvalidInputErrorMessageProps): ReactElement => {
    const { errorMessage } = props;

    return <p className='invalid-input-error-message'>{errorMessage}</p>;
}

export default InvalidInputErrorMessage;

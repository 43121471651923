import UserProfileData from './UserProfileData';

class GetProfileFromTokenResponse {
    static type: string = 'GetProfileFromTokenResponse';

    profileFromToken: UserProfileData;
      
    constructor(data: GetProfileFromTokenResponse) {
        this.profileFromToken = new UserProfileData(data.profileFromToken);
    }
}

export default GetProfileFromTokenResponse;

class InitiateSignInAuthRequest {
    static type: string = 'InitiateSignInAuthRequest';

    emailAddress: string;
      
    constructor(emailAddress: string) {
        this.emailAddress = emailAddress;
    }
}

export default InitiateSignInAuthRequest;

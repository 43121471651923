import {
  ContactPageFormData,
} from '../pages/websitecontent/contact/ContactPage';
import CustomerContactMessageData from './CustomerContactMessageData';

class SendCustomerContactMessageRequest {
    static type: string = 'SendCustomerContactMessageRequest';
    
    customerContactMessageData: CustomerContactMessageData;
    recaptchaToken: string|null;
      
    constructor(contactPageFormData: ContactPageFormData, recaptchaToken: string|null) {
        this.customerContactMessageData = new CustomerContactMessageData(contactPageFormData);
        this.recaptchaToken = recaptchaToken;
    }
}

export default SendCustomerContactMessageRequest;

class SendCustomerContactMessageResponse {
    static type: string = 'SendCustomerContactMessageResponse';
    
    messageSentSuccess: boolean;
      
    constructor(response: SendCustomerContactMessageResponse) {
        this.messageSentSuccess = response.messageSentSuccess;
    }
}

export default SendCustomerContactMessageResponse;

import './pagedefault.scss';

import React, {
  ReactElement,
  ReactNode,
} from 'react';

interface PageDefaultProps {
    children: ReactNode
}
const PageDefault = ({ children }: PageDefaultProps): ReactElement => {

    return (
        <div className='page-default'>
            {children}
        </div>
    );
};
  
export default PageDefault;

import './thankyoumessageedit.scss';

import React, {
  ChangeEvent,
  ReactElement,
} from 'react';

import UserProfileData from '../../../classes/UserProfileData';
import HelperMethods from '../../../helpers/HelperMethods';
import { EditProfileModalBodyProps } from './types';

const ThankyouMessageEdit = (props: EditProfileModalBodyProps): ReactElement => {
  const { updatedUserProfileData, setUpdatedUserProfileData, setHasMadeEdit } = props;

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setHasMadeEdit(true);
    const newThankyouMessage = event.target.value;
    setUpdatedUserProfileData(prevState => {
      return HelperMethods.cloneWithUpdates(UserProfileData, prevState, {thankyouMessage: newThankyouMessage});
    });
  }
  
  return (
    <>
      <textarea
        className='thankyou-message-input'
        placeholder='Thank you for the support!'
        spellCheck={true}
        value={updatedUserProfileData.thankyouMessage}
        onChange={onChange}
      />
    </>
  );
};
  
export default ThankyouMessageEdit;
  
import './landingpage.scss';

import { ReactElement } from 'react';

import { FaCircleCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router';

import NotSignedInNavbar from '../../components/navbar/NotSignedInNavbar';
import PageContent from '../../components/PageContent';

interface LandingPageContentSectionProps {
    backgroundColor: 'black' | 'orange-primary' | 'white';
    title: string;
    content: ReactElement;
}

const LandingPageContentSection = (props: LandingPageContentSectionProps) => {
    const { backgroundColor, title, content } = props;

    return (
        <div className={`landing-page-content-section ${backgroundColor}`}>
            <h2 className='landing-page-content-section-title'>{title}</h2>
            { content }
        </div>
    );
}

interface LandingPageImageSectionProps {
    imgSrc: NodeRequire;
    borderTopSrc: NodeRequire;
    borderBottomSrc: NodeRequire;
}

const LandingPageImageSection = (props: LandingPageImageSectionProps) => {
    let { imgSrc, borderTopSrc, borderBottomSrc } = props;

    return (
        <div className='landing-page-image-section'>
            <img className='landing-page-image-section-top-border' src={borderTopSrc.toString()}/>
            <img className='landing-page-image-section-img' src={imgSrc.toString()}/>
            <img className='landing-page-image-section-bottom-border' src={borderBottomSrc.toString()}/>
        </div>
    );
}

const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <NotSignedInNavbar isLandingPage={true}/>
            <PageContent fullWidth={true}>
                <div className='landing-page-heading'>
                    <div className='landing-page-heading-left-wrapper'>
                        <h1 className='landing-page-heading-text'>THE UK'S MOST INNOVATIVE QR CODE PAYMENTS SOLUTION</h1>
                    </div>
                    <div className='landing-page-heading-right-wrapper'>
                        <img className='landing-page-heading-shape' src={require('../../assets/images/landing-page/header-shape-orange-primary.png')}/>
                        <img className='landing-page-heading-qr-code' src={require('../../assets/images/landing-page/header-qr-code.png')}/>
                    </div>
                </div>
                <img className='landing-page-border' src={require('../../assets/images/landing-page/shape-border-bottom-orange-primary.png')}/>
                <LandingPageContentSection
                    backgroundColor='orange-primary'
                    title='HOW IT WORKS'
                    content={(
                        <>
                            <p>If you receive tips as part or all of your income, Omnia can help boost how much you earn by enabling you to receive mobile payments, including Apple Pay and Google Pay!</p>
                            <p>Our solution requires no sign-up fee, and creating an account can be completed in just a few simple steps.</p>
                            <div className='landing-page-button' onClick={() => navigate('/authenticate')}>Sign-up for free</div>
                        </>
                    )}
                />
                <LandingPageImageSection
                    imgSrc={require('../../assets/images/websitecontent/female-musician-southbank.jpg')}
                    borderTopSrc={require('../../assets/images/landing-page/shape-border-top-orange-primary.png')}
                    borderBottomSrc={require('../../assets/images/landing-page/shape-border-bottom-black.png')}
                />
                <LandingPageContentSection
                    backgroundColor='black'
                    title='WHO IS IT FOR?'
                    content={(
                        <>
                            <p>Omnia is for anyone who collects payments, including donations and tips, and wants to be able to receive mobile payments in the most efficient and cost effective way.</p>
                            <div className='landing-page-bullet-list'>
                                { ['Musicians', 'Street Performers', 'Artists', 'Hair Dressers', 'Drivers', 'Cafes', 'Restaurants'].map((item, index) => (
                                    <div key={index} className='landing-page-bullet-list-item'>
                                        <FaCircleCheck className='landing-page-bullet-list-check'/><p>{item}</p>
                                    </div>
                                ))}
                            </div>
                            <p>Not sure if Omnia is right for you?</p>
                            <div className='landing-page-button' onClick={() => navigate('/contact')}>Contact Us</div>
                        </>
                    )}
                />
                <LandingPageImageSection
                    imgSrc={require('../../assets/images/websitecontent/big-ben-close-up.png')}
                    borderTopSrc={require('../../assets/images/landing-page/shape-border-top-black.png')}
                    borderBottomSrc={require('../../assets/images/landing-page/shape-border-bottom-white.png')}
                />
                <LandingPageContentSection
                    backgroundColor='white'
                    title='ABOUT'
                    content={(
                        <>
                            <p>With more and more transactions going digital and less people carrying cash, we thought it was about time someone made a solution to allow people accept online payments in a sleek, efficient and enjoyable way.</p>
                            <p>We have created Omnia with both the user and the end customer in mind. By doing this we believe we have created the best QR code payments platform available.</p>
                            <p>For more details about our solution read our FAQ section:</p>
                            <div className='landing-page-button black' onClick={() => navigate('/faqs')}>FAQs</div>
                        </>
                    )}
                />
                <img className='landing-page-border' src={require('../../assets/images/landing-page/shape-border-top-white.png')}/>
            </PageContent>
        </>
    );
}

export default LandingPage;

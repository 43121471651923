import './aboutomniapage.scss';

import { ReactElement } from 'react';

import PageContent from '../../../components/PageContent';

const AboutOmniaPage = (): ReactElement => {
    return (
        <PageContent alignItems='left'>
            <h1>About Omnia</h1>
            <div className='about-omnia-logo-banner'>
                <img className='about-omnia-logo' src={require('../../../assets/images/omnia-logos/omnia-name-logo-white.png')}/>
            </div>
            <p>Omnia was founded in 2024 with the mission to improve how people receive tips, donations and financial support as part of their work, hobby or cause.</p>
            <p>As cash is becoming less and less common, especially in large towns and cities, many people have seen cash reliant earnings decline significantly. We believe people still want to show their appreciation for an excellent service or experience, they just haven't had a convenient way to do this.</p>
            <p>That's why we created Omnia: a clean, streamlined and transparent way for people to leave cashless payments via QR codes.</p>
            <p>We believe we have created the most user-friendly and customisable QR code payments service there is. We’d love for you to try it out and tell us what you think!</p>
        </PageContent>
    );
}

export default AboutOmniaPage;

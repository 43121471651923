import './defaultnavbar.scss';

import React, { ReactElement } from 'react';

const DefaultNavbar = (): ReactElement => {
  return (
    <div className='default-navbar'>
      <img className='default-navbar-logo' src={require('../../assets/images/omnia-logos/omnia-logo-black-background.png')} alt='logo'/>
    </div>
  );
};

export default DefaultNavbar;

import './signedinnavbar.scss';

import React, { ReactElement } from 'react';

import {
  GoHome,
  GoHomeFill,
} from 'react-icons/go';
import {
  IoQrCode,
  IoQrCodeOutline,
} from 'react-icons/io5';
import {
  PiPiggyBankFill,
  PiPiggyBankLight,
} from 'react-icons/pi';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router';

import UserProfileData from '../../classes/UserProfileData';
import {
  AppDispatch,
  RootState,
} from '../../redux/store';

interface SignedInNavbarProps {
    pageIndex: number;
    setPageIndex: (index: number) => void;
}

const SignedInNavbar = (props: SignedInNavbarProps): ReactElement => {
    const { pageIndex, setPageIndex } = props;

    const navigate = useNavigate();
    
    const reduxUserProfileData: UserProfileData | null = useSelector((state: RootState) => state.userProfileDataReducer.userProfileData);
    const dispatch = useDispatch<AppDispatch>();
    
    if (!reduxUserProfileData) {
      navigate('/');
      return <></>;
    }

    return (
      <div className='signed-in-navbar'>
          <div className='signed-in-central-navbar'>
              {pageIndex === 0 ? <GoHomeFill className='signed-in-navbar-icon'/> : <GoHome className='signed-in-navbar-icon' onClick={() => setPageIndex(0)}/>}
              { pageIndex === 1 ?
                  <img className='signed-in-navbar-icon profile-image selected' src={reduxUserProfileData.base64ProfileImage}/>
                  :
                  <img className='signed-in-navbar-icon profile-image' src={reduxUserProfileData.base64ProfileImage} onClick={() => setPageIndex(1)}/>
              }
              <div className='signed-in-navbar-logo-wrapper'>
                  <img className='navbar-logoo' onClick={() => navigate('/')} src={require('../../assets/images/omnia-logos/omnia-logo-black-background.png')} alt='logo'/>
              </div>
              {pageIndex === 2 ? <PiPiggyBankFill className='signed-in-navbar-icon'/> : <PiPiggyBankLight className='signed-in-navbar-icon' onClick={() => setPageIndex(2)}/>}
              {pageIndex === 3 ? <IoQrCode className='signed-in-navbar-icon'/> : <IoQrCodeOutline className='signed-in-navbar-icon' onClick={() => setPageIndex(3)}/>}
          </div>
      </div>
    );
};

export default SignedInNavbar;

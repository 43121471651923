import './contactpage.scss';

import {
  ReactElement,
  useRef,
  useState,
} from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { FaCircleCheck } from 'react-icons/fa6';
import { Puff } from 'react-loading-icons';
import {
  useLocation,
  useNavigate,
} from 'react-router';

import ApiResponse from '../../../classes/ApiResponse';
import SendCustomerContactMessageRequest
  from '../../../classes/SendCustomerContactMessageRequest';
import SendCustomerContactMessageResponse
  from '../../../classes/SendCustomerContactMessageResponse';
import ErrorMessage from '../../../components/ErrorMessage';
import {
  FormInputText,
  FormInputTextArea,
} from '../../../components/FormInputs';
import PageContent from '../../../components/PageContent';
import Constants from '../../../configuration/constants';

export interface ContactPageFormData {
    emailAddress: string;
    message: string;
}

const ContactPage = (): ReactElement => {
    const [emailSentSuccessfully, setEmailSentSuccessfully] = useState<boolean>(false);
    const [recaptchaToken, setRecaptchaToken] = useState<string|null>(null);
    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');

    const navigate = useNavigate();
    const location = useLocation();
    const isFromOverlay = !location.pathname.includes('/contact');

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const form = useForm<ContactPageFormData>({
        defaultValues: {
            emailAddress: '',
            message: ''
        },
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });
    const { control, handleSubmit, formState } = form;
    const { errors, isSubmitting } = formState;

    const onSubmit = async (formData: ContactPageFormData): Promise<void> => {
        setCustomerErrorMessage('');
        const request = new SendCustomerContactMessageRequest(formData, recaptchaToken);
        const response: SendCustomerContactMessageResponse|null = await ApiResponse.getApiResponse(
            Constants.sendCustomerContactMessageEndpoint,
            request,
            SendCustomerContactMessageResponse,
            null,
            setCustomerErrorMessage,
            false
        );

        if (!response || !response.messageSentSuccess) {
            setCustomerErrorMessage('We weren\'t able to send your message at this time. Please check the information you provided and try again.')
        } else {
           setEmailSentSuccessfully(true);
        }
    }

    const handleRecaptchaChange = (newToken: string|null) => {
        if (newToken !== recaptchaToken) {
            setRecaptchaToken(newToken);
            setCustomerErrorMessage('');
        }
    }
    
    return ( emailSentSuccessfully ?
        <PageContent> 
            <FaCircleCheck className='message-sent-successfully-circle-check'/>
            <h2 className='message-sent-successfully-title'>Message Sent</h2>
            <p className='message-sent-successfully-text'>We will respond to your message as soon as possible</p>
            { !isFromOverlay &&
                <div className='return-to-homepage-button' onClick={() => navigate('/')}>Return Home</div>
            }
        </PageContent>
        :
        <PageContent alignItems='left'>
            <h1>Contact Us</h1>
            <p>If you have any questions at all about our QR code solution please don't hesitate to reach out.</p>
            <p>Due to Omnia being a small start-up the best way to reach out is to get in contact with our Founder directly!</p>
            <p>Please complete the form below and your message will go directly to Billy's personal email inbox:</p>
            <form className='contact-page-form-section' onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormInputText
                    name='emailAddress'
                    label='Your Email Address'
                    placeholder='example@email.com'
                    type='email'
                    control={control}
                    helperText='The email address we will respond to'
                    errorText={errors.emailAddress?.message}
                    required={true}
                    rules={{
                        required: 'Email address is a required field',
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Invalid email address',
                        },
                        validate: {
                            leadingOrTrailingSpace: (fieldValue: string) => {
                                if (fieldValue) {
                                    return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
                                } else {
                                    return true;
                                }
                            }
                        }
                    }}
                />
                <FormInputTextArea
                    name='message'
                    label='Message'
                    placeholder='Your question or query'
                    control={control}
                    helperText='Your questions or query'
                    errorText={errors.message?.message}
                    required={true}
                    rules={{
                        required: 'Message is a required field',
                        minLength: {
                            value: 15,
                            message: 'Your message must be at least 15 characters long'
                        },
                        validate: {
                            leadingOrTrailingSpace: (fieldValue: string) => {
                                if (fieldValue) {
                                    return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
                                } else {
                                    return true;
                                }
                            }
                        }
                    }}
                />
                <ReCAPTCHA ref={recaptchaRef} className='recaptcha-section' sitekey={Constants.googleReCaptchaSiteKey} 
                    onChange={handleRecaptchaChange}
                />
                { customerErrorMessage !== '' &&
                    <ErrorMessage errorMessage={customerErrorMessage}/>
                }
                <div className='contact-page-form-submit-section'>
                    {   !isSubmitting ?
                        <button className={`contact-page-submit-form-button ${recaptchaToken !== null ? 'valid-form-state' : ''}`} disabled={isSubmitting}>Send</button>
                        : <div><Puff stroke='#FFA500'/></div>
                    }
                </div>
            </form>
        </PageContent>
    );
}

export default ContactPage;

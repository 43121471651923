/**
 * This Constants object should contain any business or product related constants.
 */
class Constants {
    static minimumTransactionAmount: number = 1;
    static maximumTransactionAmount: number = 9999.99;
    static maxPaySelectionButtons: number = 3;
    static omniaFeeMultiplier: number = 0.05;
    static omniaFeeConstant: number = 0;
    static paymentProcessingFeeMultiplier: number = 0.029;
    static paymentProcessingFeeConstant: number = 0.25;
    static otpLength: number = 6;
    static qrCodeVerbs: string[] = ['DONATE', 'SUPPORT', 'TIP', 'NONE'];
    static frontendURL: string = 'https://thisisomnia.com';
    static backendURL: string = 'https://thisisomnia-backend.com';
    static getProfileDisplayDataEndpoint: string = 'get-profile-display-data';
    static getPaymentIntentEndpoint: string = 'get-stripe-payment-intent';
    static createAccountEndpoint: string = 'create-account';
    static updateProfileEndpoint: string = 'update-profile';
    static googleReCaptchaSiteKey: string = '6LeR26opAAAAABEMHZ8elb05Xm7x8I3TvpxPowq1';
    static confirmEmailAddressEndpoint: string = 'confirm-email-address';
    static authChallengeResponseEndpoint: string = 'auth-challenge-response';
    static initiateAuthRequestEndpoint: string = 'initiate-auth-request';
    static getProfileFromTokenEndpoint: string = 'get-profile-from-token';
    static getAccountStateFromTokenEndpoint: string = 'get-account-state-from-token';
    static createStripeAccountSessionEndpoint: string = 'create-stripe-account-session';
    static sendQRCodeByEmailEndpoint: string = 'send-qr-code-by-email';
    static sendCustomerContactMessageEndpoint: string = 'send-customer-contact-email';
    static stripePublishableKey: string = 'pk_test_51ObV3iIVpqga7aO7xexL2HHbCENhJeXpbn2lAR9QXk9mQguLse4Rvr1T5EMq7kbtU6LgQdsKvHLCx9hRH6fsNSvC00sQCkfWWs';
    static omniaIdToken: string = 'omnia-id-token';
}

export default Constants;

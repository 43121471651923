class AuthChallengeResponseRequest {
    static type: string = 'AuthChallengeResponseRequest';

    emailAddress: string;
    submittedOTP: string;
    authSessionId: string;
      
    constructor(emailAddress: string, otp: string, sessionId: string|undefined) {
        this.emailAddress = emailAddress;
        this.submittedOTP = otp;
        this.authSessionId = sessionId ? sessionId : '';
    }
}

export default AuthChallengeResponseRequest;

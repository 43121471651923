import './connectedaccountpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { FaStripe } from 'react-icons/fa';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';

import ApiResponse from '../../classes/ApiResponse';
import CreateStripeAccountSessionResponse
  from '../../classes/CreateStripeAccountSessionResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import DefaultNavbar from '../../components/navbar/DefaultNavbar';
import PageContent from '../../components/PageContent';
import Constants from '../../configuration/constants';
import StyleVariables from '../../styles/base/variables';

const ConnectedAccountPage = (): ReactElement => {
    const [stripeAccountLink, setStripeAccountLink] = useState<string>('');
    const [showStripeOnboardinElement, setShowStripeOnboardingElement] = useState<boolean>(false);
    const [stripeConnectInstance] = useState<StripeConnectInstance>(() => {
        const fetchClientSecret = async (): Promise<string> => {
            return await ApiResponse.getApiResponse(
                Constants.createStripeAccountSessionEndpoint,
                null,
                CreateStripeAccountSessionResponse, 
                null,
                null,
                true
            ).then(response => {
                return response ? response.clientSecret : '';
            });
        };

        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };
        
        getAccountStateFromToken().then(response => {
            if (response instanceof AxiosError && response.request.status === 401) {
                navigate('/authenticate');
            } else if (response instanceof AxiosError || !response) {
                navigate('/');
            } else if (!response.accountCreated ) {
                navigate('/create-account');
            } else if (response.stripeOnboardingCompleted) {
                navigate('/');
            }
        });
        
        /**
         * Appearance options: https://docs.stripe.com/connect/embedded-appearance-options
         */
        return loadConnectAndInitialize({
            publishableKey: Constants.stripePublishableKey,
            fetchClientSecret: fetchClientSecret,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Nunito:300,600&display=swap'
                }
            ],
            appearance: {
                variables: {
                    fontFamily: `${StyleVariables.primaryFontFamily}, sans-serif`,
                    colorPrimary: StyleVariables.orangePrimary,
                    colorDanger: StyleVariables.errorRedLight,
                    borderRadius: StyleVariables.pillButtonBorderRadius,
                    colorText: StyleVariables.black,

                    buttonPrimaryColorBackground: StyleVariables.black,
                    buttonPrimaryColorBorder: StyleVariables.black,
                    buttonPrimaryColorText: StyleVariables.white,

                    buttonSecondaryColorBackground: StyleVariables.black,
                    buttonSecondaryColorBorder: StyleVariables.black,
                    buttonSecondaryColorText: StyleVariables.white,

                    actionPrimaryColorText: StyleVariables.orangePrimary,
                    actionSecondaryColorText: StyleVariables.black,
                    badgeNeutralColorBackground: StyleVariables.greyLight,
                    badgeNeutralColorBorder: StyleVariables.greyLight,
                    badgeNeutralColorText: StyleVariables.black,

                    badgeSuccessColorBackground: StyleVariables.successGreenLight,
                    badgeSuccessColorText: StyleVariables.successGreenDark,
                    badgeSuccessColorBorder: StyleVariables.successGreenLight,

                    badgeWarningColorBackground: StyleVariables.errorRedLight,
                    badgeWarningColorText: StyleVariables.errorRedDark,
                    badgeWarningColorBorder: StyleVariables.errorRedLight,

                    formAccentColor: StyleVariables.orangePrimary,

                    buttonBorderRadius: StyleVariables.pillButtonBorderRadius,
                    colorSecondaryText: StyleVariables.greyMid,
                    colorBorder: StyleVariables.black,
            
                    headingLgFontSize: StyleVariables.headingLargeFontSize,
                    headingXlFontSize: StyleVariables.headingXLargeFontSize,
                    bodySmFontSize: StyleVariables.bodySmallFontSize,
                    bodyMdFontSize: StyleVariables.bodyMediumFontSize,
                    labelMdFontSize: StyleVariables.buttonSmallFontSize,
                    labelSmFontSize: StyleVariables.bodySmallFontSize,
                    // headingMdFontSize: '5px',
                    // headingSmFontSize: '',
                    // headingXsFontSize: '',
                    // spacingUnit: ''
                }
            }
        });
    });

    const navigate = useNavigate();
    
    return (
        <>
            <DefaultNavbar/>
            <PageContent>
                { showStripeOnboardinElement ? 
                    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                        <ConnectAccountOnboarding
                            onExit={() => {
                                navigate('/');
                            }}
                            // Optional: make sure to follow our policy instructions above
                            // fullTermsOfServiceUrl="{{URL}}"
                            // recipientTermsOfServiceUrl="{{URL}}"
                            // privacyPolicyUrl="{{URL}}"
                            // skipTermsOfServiceCollection={false}
                            // collectionOptions={{
                            //   fields: 'eventually_due',
                            //   futureRequirements: 'include',
                            // }}
                        />                   
                    </ConnectComponentsProvider>
                    : <div className='connected-account-page-centre-content'>
                        <div className='connect-to-stripe-icon-wrapper'><FaStripe className='connect-to-stripe-icon'/></div>
                        <div className='connect-to-stripe-title-wrapper'><div className='connect-to-stripe-title'>Connect your account with Stripe</div></div>
                        <div className='connect-to-stripe-text'>We've partnered with Stripe to make sure you get paid on time and to keep your personal and bank details secure.</div>
                        <FaCircleArrowRight className='connect-to-stripe-next-arrow' onClick={() => setShowStripeOnboardingElement(true)}/>
                    </div>

                }               
            </PageContent>
        </>
    );
}

export default ConnectedAccountPage;

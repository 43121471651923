import './emailqrcodemodal.scss';

import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import OmniaModal from '../../components/OmniaModal';

interface DownloadQRCodeModalProps {
    createQRCodeHTMLElement: () => Promise<HTMLElement | null>;
    buildPngFromHTMLElement: (element: HTMLElement) => Promise<string>;
    onClose: () => void;
    isVisible: boolean;
}

const DownloadQRCodeModal = (props: DownloadQRCodeModalProps): ReactElement => {
    const { createQRCodeHTMLElement, buildPngFromHTMLElement, onClose, isVisible } = props;

    const [downloadingQRCode, setDownloadingQRCode] = useState<boolean>(false);
    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');


    useEffect(() => {
        if (!isVisible) {
            setDownloadingQRCode(false);
        }
    }, [isVisible]);

    const downloadQRCode = async () => {
        setDownloadingQRCode(true);
        const element = await createQRCodeHTMLElement();

        if (element) {
            try {
                await new Promise(resolve => setTimeout(resolve, 1000)); // Ensure the element is rendered

                const dataUrl = await buildPngFromHTMLElement(element);
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'omnia-qr-code.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);   
                setCustomerErrorMessage('');
            } catch (error) {
                setCustomerErrorMessage('An issue occured while downloading your code. Please refresh the page and try again.')
            }
        }

        setDownloadingQRCode(false);
        onClose();
    };

    return (
        <OmniaModal
            modalTitle='Download QR Code'
            modalDescription='The downloaded image will be stored in your browser or in your photos'
            actionFunction={downloadQRCode}
            actionVerb='Download'
            actionInProgress={downloadingQRCode}
            onClose={onClose}
            customerErrorMessage={customerErrorMessage}
            isVisible={isVisible}
        />
    );
}

export default DownloadQRCodeModal;

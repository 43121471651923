import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import ApiResponse from '../classes/ApiResponse';
import GetProfileFromTokenResponse
  from '../classes/GetProfileFromTokenResponse';
import UserProfileData from '../classes/UserProfileData';
import Constants from '../configuration/constants';

interface UserProfileDataState {
    userProfileData: UserProfileData | null;
}

const initialState: UserProfileDataState = {
    userProfileData: null
};

const userProfileDataSlice = createSlice({
    name: 'userProfileDataSlice',
    initialState,
    reducers: {
        updateUserProfileData: (state, action: PayloadAction<UserProfileData>) => {
            state.userProfileData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProfileDataAsync.fulfilled, (state, action: PayloadAction<UserProfileData|null>) => {
            state.userProfileData = action.payload;
        });
    }
});

export const getUserProfileDataAsync = createAsyncThunk(
    'getUserProfileDataAsync',
    async () => {
        const response = await ApiResponse.getApiResponse(
            Constants.getProfileFromTokenEndpoint,
            null,
            GetProfileFromTokenResponse,
            null,
            null,
            true
        );
        
        if (response) {
            return response.profileFromToken;
        } else {
            return null;
        }
    }
);

export const { updateUserProfileData } = userProfileDataSlice.actions;

export default userProfileDataSlice.reducer;

import './enteremailaddresspage.scss';

import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';
import { useNavigate } from 'react-router';

import ApiResponse from '../../classes/ApiResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import InitiateSignInAuthRequest from '../../classes/InitiateSignInAuthRequest';
import InitiateSignInAuthResponse
  from '../../classes/InitiateSignInAuthResponse';
import ErrorMessage from '../../components/InvalidInputErrorMessage';
import InvalidInputErrorMessage
  from '../../components/InvalidInputErrorMessage';
import LoadingIcon from '../../components/LoadingIcon';
import PageContent from '../../components/PageContent';
import Constants from '../../configuration/constants';
import { EnterEmailAddressPageFormData } from './types';

const invalidEmailAddress = 'invalid-email-address';
const verificationCodeMessage = 'verification-code-message';

interface EnterEmailAddressPageProps {
    setShowEnterOTPPage: (state: boolean) => void;
    setEmailAddress: (emailAddress: string) => void;
}

const EnterEmailAddressPage = (props: EnterEmailAddressPageProps): ReactElement => {
    const { setShowEnterOTPPage, setEmailAddress } = props;

    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');
    const [loadingPage, setLoadingPage] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };

        if (loadingPage) {
            getAccountStateFromToken().then(response => {
                if (!(response instanceof AxiosError && response.request.status === 401)) {
                    if (response && !response.accountCreated) {
                        navigate('/create-account');
                    } else if (response && response.accountCreated && !response.stripeOnboardingCompleted) {
                        navigate('/connected-account');
                    } else {
                        navigate('/');
                    }
                }
            });
            setLoadingPage(false);
        }
    }, [loadingPage, navigate]);

    const form = useForm<EnterEmailAddressPageFormData>({
        defaultValues: {
            emailAddress: ''
        },
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });
    const { handleSubmit, formState, register } = form;
    const { errors, isValid, isSubmitting } = formState;

    const onSubmitEmailAddress = async (formData: EnterEmailAddressPageFormData) => {
        setCustomerErrorMessage('');
        const initiateSignInAuthRequest = new InitiateSignInAuthRequest(formData.emailAddress);
        const response: InitiateSignInAuthResponse|null = await ApiResponse.getApiResponse(
            Constants.initiateAuthRequestEndpoint,
            initiateSignInAuthRequest,
            InitiateSignInAuthResponse,
            null,
            setCustomerErrorMessage,
            false
        );
        
        if (!response || !response.authSessionId) {
            return;
        } else {
            Cookies.set('omnia-auth-session-id', response.authSessionId, { 
                path: '/',
                secure: false, // If testing using localhost on Safari, this must be set to false
                sameSite: 'strict',
                expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // 1 year from now
            });
            setShowEnterOTPPage(true);
            setEmailAddress(formData.emailAddress);
        }
    }

    if (loadingPage) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
    }

    return (
        <PageContent>
            <div className='enter-email-address-page-centre-content'>
                <div className='email-icon-wrapper'><IoMdMail className='email-icon'/></div>
                <div className='email-title-wrapper'><div className='whats-your-email-title'>What's your email address?</div></div>
                <form onSubmit={handleSubmit(onSubmitEmailAddress)}>
                    <input 
                        className={'email-address-input' + (errors.emailAddress ? ' ' + invalidEmailAddress : '')}
                        type='email'
                        id='emailAddress'
                        placeholder='example@email.co.uk'
                        spellCheck={false}
                        {...register('emailAddress', {
                            required: 'Please enter your email',
                            pattern: {
                                value: /^[a-zA-Z0-9_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: 'Invalid email format'
                            },
                            validate: {
                                leadingOrTrailingSpace: (fieldValue) => {
                                    return fieldValue.trim() === fieldValue || 'Email address cannot start or end with a space'
                                }
                            }
                        })}
                        disabled={isSubmitting}
                    />
                    { errors.emailAddress?.message && <InvalidInputErrorMessage errorMessage={errors.emailAddress.message}/> }
                    <div className={verificationCodeMessage}>We will send you an email containing a verification code</div>
                    { customerErrorMessage &&  <ErrorMessage errorMessage={customerErrorMessage}/> }
                    { isSubmitting ? <LoadingIcon/>
                        : <FaCircleArrowRight className={`next-arrow ${!isValid ? 'disabled' : ''}`} onClick={handleSubmit(onSubmitEmailAddress)}/>
                    }
                </form>
            </div>
        </PageContent>
    );
}

export default EnterEmailAddressPage;

import { useState } from 'react';

import SignedInNavbar from '../../components/navbar/SignedInNavbar';
import PageContent from '../../components/PageContent';
import DashboardPage from '../dashboard/DashboardPage';
import QRCodePage from '../qrcode/QRCodePage';
import SignedInProfilePage from '../signedinprofilepage/SignedInProfilePage';
import SignedInHomePage from '../singedinhome/SignedInHomePage';

interface SignedInPage {
  element: JSX.Element;
}
  
const signedInPages : SignedInPage[] = [
  { element: <SignedInHomePage/> },
  { element: <SignedInProfilePage/> },
  { element: <DashboardPage/> },
  { element: <QRCodePage/> }
];

const SignedInPage = () => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  
  return (
    <>
      <SignedInNavbar pageIndex={pageIndex} setPageIndex={setPageIndex}/>
      <PageContent>
        {signedInPages[pageIndex].element}
      </PageContent>
    </>
  );
}

export default SignedInPage;

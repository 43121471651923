import UserProfileData from './UserProfileData';

class UpdateProfileRequest {
    static type: string = 'UpdateProfileRequest';
    
    updatedUserProfileData: UserProfileData;
      
    constructor(data: UserProfileData) {
        this.updatedUserProfileData = new UserProfileData(data);
    }
}

export default UpdateProfileRequest;

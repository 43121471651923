import './index';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import NotSignedInNavbar from './components/navbar/NotSignedInNavbar';
import PageDefault from './components/PageDefault';
import AuthenticatePage from './pages/authenticate/AuthenticatePage';
import ConnectedAccountPage
  from './pages/connectedaccount/ConnectedAccountPage';
import CreateAccountPage from './pages/createaccount/CreateAccountPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import HomePage from './pages/homepage/HomePage';
import PaymentPage from './pages/payment/PaymentPage';
import PaymentConfirmationPage
  from './pages/paymentconfirmation/PaymentConfirmationPage';
import AboutOmniaPage from './pages/websitecontent/aboutomnia/AboutOmniaPage';
import ContactPage from './pages/websitecontent/contact/ContactPage';
import FAQsPage from './pages/websitecontent/faqs/FAQsPage';
import TaxOnTipsPage from './pages/websitecontent/taxontips/TaxOnTipsPage';
import TippingTipsPage
  from './pages/websitecontent/tippingtips/TippingTipsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/send/:username' element={<PageDefault><PaymentPage/></PageDefault>}/>
        <Route path='/' element={<PageDefault><HomePage/></PageDefault>} />
        <Route path='/payment-confirmation/:username' element={<PageDefault><PaymentConfirmationPage/></PageDefault>}/>
        <Route path='/authenticate' element={<PageDefault><AuthenticatePage/></PageDefault>}/>
        <Route path='/create-account' element={<PageDefault><CreateAccountPage/></PageDefault>}/>
        <Route path='/connected-account' element={<PageDefault><ConnectedAccountPage/></PageDefault>}/>
        <Route path='/dashboard' element={<PageDefault><DashboardPage/></PageDefault>}/>
        <Route path='/tax-on-tips' element={<PageDefault><NotSignedInNavbar/><TaxOnTipsPage/></PageDefault>}/>
        <Route path='/tipping-tips' element={<PageDefault><NotSignedInNavbar/><NotSignedInNavbar/><TippingTipsPage/></PageDefault>}/>
        <Route path='/about' element={<PageDefault><NotSignedInNavbar/><AboutOmniaPage/></PageDefault>}/>
        <Route path='/contact' element={<PageDefault><NotSignedInNavbar/><ContactPage/></PageDefault>}/>
        <Route path='/faqs' element={<PageDefault><NotSignedInNavbar/><FAQsPage/></PageDefault>}/>

        {/* Default route that redirects to the homepage */}
        <Route path='*' element={<Navigate to='/'/>}/>
      </Routes>
    </Router>
  );
}

export default App;

import {
  ContactPageFormData,
} from '../pages/websitecontent/contact/ContactPage';

class CustomerContactMessageData {
    static type: string = 'CustomerContactMessageData';
    
    emailAddress: string;
    message: string;

    constructor(data: ContactPageFormData) {
      this.emailAddress = data.emailAddress;
      this.message = data.message;
    }
}
  
export default CustomerContactMessageData;
  
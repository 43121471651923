class GetAccountStateFromTokenResponse {
    static type: string = 'GetAccountStateFromTokenResponse';

    accountCreated: string;
    stripeOnboardingCompleted: string;
      
    constructor(data: GetAccountStateFromTokenResponse) {
        this.accountCreated = data.accountCreated;
        this.stripeOnboardingCompleted = data.stripeOnboardingCompleted;
    }
}

export default GetAccountStateFromTokenResponse;

import './omniaqrcode.scss';

import {
  forwardRef,
  ReactElement,
} from 'react';

import { QRCodeSVG } from 'qrcode.react';

import UserProfileData from '../../classes/UserProfileData';

type SizeType = 'preview' | 'full-size-modal' | 'email-and-download';

interface OmniaQRCodeProps {
    userProfileData: UserProfileData;
    sizeType: SizeType;
}

const OmniaQRCode = forwardRef<HTMLDivElement, OmniaQRCodeProps>((props: OmniaQRCodeProps, ref): ReactElement => {
    const { userProfileData, sizeType } = props;

    const calculateSize = (sizeType: SizeType): number => {
        switch (sizeType) {
            case 'preview':
                return 150;
            case 'full-size-modal':
                return 270;
            case 'email-and-download':
                return 750;
        }
    }

    const size = calculateSize(sizeType);

    return (
        <div ref={ref} className={'qr-code-container' + ' ' + sizeType}>
            { userProfileData.qrCodeVerb && userProfileData.qrCodeVerb !== 'NONE' &&
                <p className={'qr-code-verb-text' + ' ' + sizeType}>{userProfileData.qrCodeVerb}</p>
            }
            <QRCodeSVG
                className={'qr-code-image' + ' ' + sizeType}
                value={`https://thisisomnia.com/send/${userProfileData.username}`}
                size={size}
                level='Q'
                includeMargin={false}
                imageSettings={{
                    src: require('../../assets/images/omnia-logos/omnia-logo-black-background-small-rounded.png'),
                    height: size/3.5,
                    width: size/3.5,
                    excavate: true
                }}
            />
            <div className={'powered-by-message' + ' ' +  sizeType}>
                <p className={'powered-by-text' + ' ' + sizeType}>Powered by</p>
                <img
                    className={'qr-code-omnia-logo' + ' ' + sizeType}
                    src={require('../../assets/images/omnia-logos/omnia-name-logo-black.png')} alt='Omnia.'
                />
            </div>
        </div>
    );
});

export default OmniaQRCode;

import './paymentconfirmationpageviewedittab.scss';

import {
  ReactElement,
  useEffect,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router';

import UserProfileData from '../../classes/UserProfileData';
import LoadingIcon from '../../components/LoadingIcon';
import HelperMethods from '../../helpers/HelperMethods';
import {
  AppDispatch,
  RootState,
} from '../../redux/store';
import { getUserProfileDataAsync } from '../../redux/userProfileDataSlice';
import PaymentConfirmationPageProfile
  from '../paymentconfirmation/PaymentConfirmationPageProfile';

const PaymentConfirmationPageViewEditTab = (): ReactElement => {
    const navigate = useNavigate();
    
    const reduxUserProfileData: UserProfileData | null = useSelector((state: RootState) => state.userProfileDataReducer.userProfileData);
    const dispatch = useDispatch<AppDispatch>();
    
    useEffect(() => {
        if (!reduxUserProfileData) {
          console.log('getting accountState from PaymentConfirmationPageViewEditTab');
          dispatch(getUserProfileDataAsync());
        }
    }, [reduxUserProfileData, dispatch]);
    
    if (!reduxUserProfileData) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
    }

    return (
      <div className='thankyou-profile-preview-wrapper'>
        <div className='thankyou-profile-preview'>
          <PaymentConfirmationPageProfile
            profileDisplayData={HelperMethods.getProfileDisplayDataFromUserProfileData(reduxUserProfileData)}
            userProfileData={reduxUserProfileData}
            isPreview={true}
          />
        </div>
      </div>
    );
}

export default PaymentConfirmationPageViewEditTab;

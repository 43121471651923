import HelperMethods from '../helpers/HelperMethods';
import { SocialMediaLinks } from '../types/types';

class ProfileDisplayData {
  static type: string = 'ProfileDisplayData';
  
  displayName: string;
  username: string;
  stripeConnectedAccountId: string;
  base64ProfileImage: string;
  suggestedPayValues: number[];
  thankyouMessage: string;
  socialMediaLinks: SocialMediaLinks;
  currency: string;
  qrCodeVerb: string;
  
  constructor(data: ProfileDisplayData) {
    this.displayName = data.displayName;
    this.username = data.username;
    this.stripeConnectedAccountId = data.stripeConnectedAccountId;
    this.base64ProfileImage = data.base64ProfileImage;
    this.suggestedPayValues = HelperMethods.getValidButtonValues(data.suggestedPayValues);
    this.thankyouMessage = data.thankyouMessage;
    this.socialMediaLinks = HelperMethods.getValidSocialMediaLinks(data.socialMediaLinks);
    this.currency = data.currency;
    this.qrCodeVerb = data.qrCodeVerb;
  }
}

export default ProfileDisplayData;

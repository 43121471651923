import './signedinprofilepage.scss';

import React, {
  ReactElement,
  useState,
} from 'react';

import { FaUserEdit } from 'react-icons/fa';
import { IoSettingsSharp } from 'react-icons/io5';

import Settings from '../settings/Settings';
import ViewEditProfilePage from '../vieweditprofile/ViewEditProfilePage';

interface SignedInProfilePageTabItem {
    icon: ReactElement;
    element: ReactElement;
};
      
const tabs: SignedInProfilePageTabItem[] = [
    { icon: <FaUserEdit/>, element: <ViewEditProfilePage/> },
    { icon: <IoSettingsSharp/>, element: <Settings/> }
];

const SignedInProfilePage = (): ReactElement => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    return (
        <>
            <div className='signed-in-profile-page-navbar'>
                <div className='signed-in-profile-page-navbar-content'>
                    { tabs.map((item, index) => (
                        <div 
                            key={index}
                            className={`signed-in-profile-page-navbar-button ${selectedTabIndex === index ? 'selected' : ''}`}
                            onClick={() => setSelectedTabIndex(index)}
                        >
                            { React.cloneElement(item.icon, {
                                className: `signed-in-profile-page-navbar-icon ${selectedTabIndex === index ? 'selected' : ''}`
                            })}
                        </div>
                    ))}
                </div>
            </div>
            <div className='signed-in-profile-page-tab-content'>
                { tabs[selectedTabIndex].element }
            </div>
        </>
    );
}

export default SignedInProfilePage;

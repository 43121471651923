import './errormessage.scss';

interface ErrorMessageProps {
    errorMessage: string|undefined;
}

const ErrorMessage = (props: ErrorMessageProps) => {
    const { errorMessage } = props;

    if (!errorMessage) {
        return null;
    }
    
    return (
        <div className='error-section'>
            <div className='error-text'>{errorMessage}</div>
        </div>
    )
}

export default ErrorMessage;

import './qrcodeverbedit.scss';

import {
  ChangeEvent,
  ReactElement,
} from 'react';

import UserProfileData from '../../../classes/UserProfileData';
import Constants from '../../../configuration/constants';
import HelperMethods from '../../../helpers/HelperMethods';
import { EditProfileModalBodyProps } from './types';

const QRCodeVerbEdit = (props: EditProfileModalBodyProps): ReactElement => {
    const { updatedUserProfileData,
        setUpdatedUserProfileData,
        setHasMadeEdit } = props;

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setHasMadeEdit(true);
        const newQRCodeVerb = event.target.value;
        setUpdatedUserProfileData(prevState => {
          return HelperMethods.cloneWithUpdates(UserProfileData, prevState, {qrCodeVerb: newQRCodeVerb});
        });
    }

    return (
        <div className='qr-code-verb-options'>
            {Constants.qrCodeVerbs.map((verb, index) => (
                <label>
                    <input
                        className='qr-code-verb-radio-button'
                        key={index}
                        type='radio'
                        value={verb}
                        checked={updatedUserProfileData.qrCodeVerb === verb}
                        onChange={onChange}
                    />
                    {verb}
                </label>
            ))}
        </div>
    );
}

export default QRCodeVerbEdit;

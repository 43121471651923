import './profilenotfoundpage.scss';

import { GiBrokenHeart } from 'react-icons/gi';
import { TbError404 } from 'react-icons/tb';

const ProfileNotFoundPage = () => {
    return (
        <>
            <TbError404 className='no-profile-404-icon'/>
            <GiBrokenHeart className='no-profile-heart-icon'/>
            <h1 className='no-profile-title'>Oh Dear!</h1>
            <p className='no-profile-text'>This QR code doesn't link to an existing profile. If you have just scanned a QR code let the owner know that their code is broken!</p>
        </>
    );
}

export default ProfileNotFoundPage;
class GetPaymentIntentResponse {
  static type: string = 'GetPaymentIntentResponse';

  clientSecret: string;
  
  constructor(data: GetPaymentIntentResponse) {
    this.clientSecret = data.clientSecret;
  }
}

export default GetPaymentIntentResponse;

import './tippingtipspage.scss';

import { ReactElement } from 'react';

import PageContent from '../../../components/PageContent';

const TippingTipsPage = (): ReactElement => {
    return (
        <PageContent alignItems='left'>
            <h1>Tipping Tips</h1>
            <img className='tipping-tips-image' src={require('../../../assets/images/websitecontent/stacks-of-coins.jpg')}/>
            <h2>Create a Memorable Experience</h2>
            <p>People are more likely to tip when they feel a connection. Focus on creating a positive, memorable experience for your audience or clients. This could be through a friendly conversation, a unique performance, or simply offering exceptional service.</p>
            <p>When people feel valued, they’re more inclined to show their appreciation with a tip.</p>  
            <h2>Engage with Your Audience</h2>
            <p>If you're a street performer or musician, engaging with your audience is key. Smile, make eye contact, and show your appreciation for their presence. A simple "thank you" or a shout-out during a performance can make people feel more involved, encouraging them to tip.</p>
            <h2>Make your Omnia QR Code Visible and Easy to Use</h2>
            <p>Make sure your QR code is large, visible, and easy to scan from a distance. Place it where it can be easily seen and scanned without your customers having to ask. Using Omnia's QR Code Verbs feature means you don't have to inform people what your QR code is for.</p>
            <p>If you are a performer having your Omnia QR code visible throughout your performance means people don't have to wait until the end to show their appreciation.</p>
            <p>The easier you make it for people to tip, the more likely they are to do so!</p>
            <h2>Show Gratitude</h2>
            <p>A little gratitude goes a long way. Always thank your customers or audience members for their tips, whether verbally or with a sign. This acknowledgment can make them feel appreciated and encourage them to tip again in the future.</p>
        </PageContent>
    );
}

export default TippingTipsPage;
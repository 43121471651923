import './omniapageoverlay.scss';

import {
  ReactElement,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';

import { IoClose } from 'react-icons/io5';

interface OmniaPageOverlayProps {
    content: ReactElement;
    onClose: () => void;
}

const OmniaPageOverlay = (props: OmniaPageOverlayProps) => {
    const { content, onClose } = props;
    
    useEffect(() => {
        // Stops the background from being scrollable
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden'  
              
        // Cleanup function to reset overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto' 
        };
    }, []);
    
    return ReactDOM.createPortal(
        <div className='page-overlay'>
            <div className='page-overlay-navbar'>
                <div className='page-overlay-central-navbar'>
                    <img className='page-overlay-navbar-logo' src={require('../assets/images/omnia-logos/omnia-name-logo-white.png')}/>
                    <div className='page-overlay-close-icon-wrapper' onClick={onClose}>
                        <IoClose className='page-overlay-close-icon'/>
                    </div>
                </div>
            </div>
            { content }
            <div className='overlay-bottom-margin'></div>
        </div>,
        document.body
    );
}

export default OmniaPageOverlay;

class AuthChallengeResponseResponse {
    static type: string = 'AuthChallengeResponseResponse';

    idToken: string;
    authRequestExpired: string;
    authSessionId: string;

    constructor(data: AuthChallengeResponseResponse) {
        this.idToken = data.idToken;
        this.authRequestExpired = data.authRequestExpired;
        this.authSessionId = data.authSessionId;
    }
}

export default AuthChallengeResponseResponse;

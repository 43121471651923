class InitiateSignInAuthResponse {
    static type: string = 'InitiateSignInAuthResponse';

    authSessionId: string;
      
    constructor(data: InitiateSignInAuthResponse) {
        this.authSessionId = data.authSessionId;
    }
}

export default InitiateSignInAuthResponse;
